<template>
  <button
  type="button"
    :disabled="disabledBtn"
    @click.stop.prevent="buttonHandler"
    :class="`
    ${btnBorder ? `border border-${borderColor} ` : ''}
    bg-${btnColor} hover:bg-opacity-75
    text-${textColor}
    ${btnSizeVal === 'chip' ? 'px-5 py-1 heading-6 rounded' : ''}
    ${btnSizeVal === 'large' ? 'py-3 px-5 cta-text' : ''}
    ${btnSizeVal === 'planChip' ? 'py-3 px-6 cta-text font-semibold' : ''}
    ${btnSizeVal === 'medium' ? 'py-2 px-5 cta-text' : ''}
    ${btnSizeVal === 'small' ? 'py-2 px-3 heading-7' : ''}
    ${isCurNormal ? 'cursor-default' : 'cursor-pointer'}
    ${disabledBtn ? 'opacity-20 cursor-auto ' : ''}
    ${isSqure ? '' : 'rounded-xl'}
    `"
    class=" shadow  hover:shadow-xl global_btn"
  >
    {{ btnText }}
  </button>
</template>
<script>
export default {
  props: ["isCurNormal",  "isSqure", "btnText", "btnSize", "btnColor", "textColor", "disabled", "btnBorder", "borderColor"],
  data() {
    return {
      disabledBtn: false,
      screenWidth: 0,
      btnSizeVal: ''
    };
  },
  watch: {
    disabled: {
      handler() {
        if (this.disabled) {
          this.disabledBtn = this.disabled;
        } else {
          this.disabledBtn = this.disabled;
        }
      },
    },
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    if (window.innerWidth < 640) {
        this.btnSizeVal = 'small'
      } else{
        this.btnSizeVal = this.btnSize
      }
    if (this.disabled) {
      this.disabledBtn = this.disabled;
    }
  },
  methods: {
    resizeWindowHandler() {
      this.screenWidth = window.innerWidth
      if (window.innerWidth < 640) {
        this.btnSizeVal = 'small'
      } else {
        this.btnSizeVal = this.btnSize
      }
    },
    buttonHandler() {
      this.$emit("buttonAction");
    },
  },
};
</script>
<style scoped>
.goTop_btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 1000;
}
</style>

