import Layout from '@/View/Admin/Layout/layout.vue'
import colors from '@/assets/colors.json'
export const Routing = { // dashboard object start
    path: '/',
    component: Layout,
    hidden: false,
    meta: {
        title: 'dashboards',
        icon: 'fas fa-border-all',
    },
    children: [
        {
            path: '/',
            component: () =>
                import ('@/View/Admin/dashboard/dashboard.vue'),
            name: 'Dashboard',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Dashboard',
                loadPage: 'Dashboard',
                permissionList: ['abc', 'def'],
                routeIcon: 'dashboardSvg',
                selected: false,
                routIconSize: { height: '25px', width: '25px', color: colors.text2 }
            }
        },
        {
            path: '/Campaigns',
            component: () =>
                import ('@/View/Admin/campaign/listing.vue'),
            name: 'Campaigns',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Campaign',
                loadPage: 'Campaigns',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'orgSideIcon',
                routIconSize: { height: '30px', width: '30px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/list',
            component: () =>
                import ('@/View/Admin/list/listing.vue'),
            name: 'Lists',
            meta: {
                icon: 'fas fa-border-all',
                title: 'List',
                loadPage: 'Lists',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'listSideBar',
                routIconSize: { height: '28px', width: '28px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/leads',
            component: () =>
                import ('@/View/Admin/leads/listing.vue'),
            name: 'Leads',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Leads',
                loadPage: 'Leads',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'customerSide',
                routIconSize: { height: '28px', width: '28px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/setting',
            component: () =>
                import ('@/View/Admin/setting/dashboard.vue'),
            name: 'Setting',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Setting',
                loadPage: 'Setting',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'SettingSvg',
                routIconSize: { height: '25px', width: '25px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/list/sublist/:listId',
            component: () =>
                import ('@/View/Admin/list/subview.vue'),
            name: 'subListListing',
            hidden: true,
            meta: {
                title: 'subListListing',
                loadPage: 'Lists',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/campaign/:campaignMasterId',
            component: () =>
                import ('@/View/Admin/campaign/detail.vue'),
            name: 'campaignDetail',
            hidden: true,
            meta: {
                title: 'campaignDetail',
                loadPage: 'Campaigns',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/campaign/add/:campaignMasterId',
            component: () =>
                import ('@/View/Admin/campaign/addCampaign.vue'),
            name: 'campaignAdd',
            hidden: true,
            meta: {
                title: 'campaignAdd',
                loadPage: 'Campaigns',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/campaign/edit/:campaignMasterId',
            component: () =>
                import ('@/View/Admin/campaign/editCampaign.vue'),
            name: 'campaignEdit',
            hidden: true,
            meta: {
                title: 'campaignEdit',
                loadPage: 'Campaigns',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/lead/:leadId',
            component: () =>
                import ('@/View/Admin/leads/detail.vue'),
            name: 'leadDetail',
            hidden: true,
            meta: {
                title: 'leadDetail',
                loadPage: 'Leads',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/globalsetting',
            component: () =>
                import ('@/View/Admin/setting/global/index.vue'),
            name: 'GlobalSetting',
            hidden: true,
            meta: {
                title: 'GSetting',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/staff',
            component: () =>
                import ('@/View/Admin/setting/staff/staffList.vue'),
            name: 'StaffListing',
            hidden: true,
            meta: {
                title: 'Staff Listing',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/staff/detail/:userDetailId',
            component: () =>
                import ('@/View/Admin/setting/staff/staffDetail.vue'),
            name: 'StaffDetail',
            hidden: true,
            meta: {
                title: 'Staff Detail',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/staff/add/:userDetailId',
            component: () =>
                import ('@/View/Admin/setting/staff/staffAdd.vue'),
            name: 'StaffAdd',
            hidden: true,
            meta: {
                title: 'Add Staff',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
    ]
}